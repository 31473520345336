import { createSlice } from '@reduxjs/toolkit';
import foodsData from "../../assets/metaDate.json";

const initialState = {
    activeCategory: 0, // ----- Название категории, которую выбрали, по умолчанию стоит 0, то есть первая 
    activeSubCategory: 0, // ----- Название субкатегории, которую выбрали, по умолчанию стоит 0, то есть первая 
    categoryS: "Основное блюдо", // ----- Название категории по умолчанию
    lang: "ru", // ----- Выбранный язык по умолчанию
    filteredCategory: null,
    searchValue: "", // ----- Поисковая строка, куда будут запушиваться запросы клиента, фильтрация еды и напитков по запросу
    data: foodsData, // ----- оцифрованное меню в файле json

}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setActiveCategory: (state, action) => {
            state.activeCategory = action.payload
        },
        setActiveSubCategory: (state, action) => {
            state.activeSubCategory = action.payload
        },
        setCategoryS: (state, action) => {
            state.categoryS = action.payload
        },
        setLang: (state, action) => {
            state.lang = action.payload
        },
        setFilteredCategory: (state, action) => {
            state.filteredCategory = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setActiveCategory, setActiveSubCategory, setCategoryS, setLang, setFilteredCategory, setSearchValue } = menuSlice.actions

export default menuSlice.reducer