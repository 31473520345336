import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    cartItems: [], // ----- Название категории, которую выбрали, по умолчанию стоит 0, то есть первая 
    totalPrice: 0,
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartItems: (state, action) => {
            const findItem = state.cartItems.find((obj) => obj.main["ru"].name === action.payload.main["ru"].name);
            if (findItem) {
                findItem.count++;
            } else {
                state.cartItems.push({ ...action.payload, count: 1 });
            }
            state.totalPrice = state.cartItems.reduce((sum, obj) => {
                return (obj.base.price * obj.count) + sum;
            }, 0)
        },
        minusItem: (state, action) => {
            const findItem = state.cartItems.find((obj) => obj.main["ru"].name === action.payload.main["ru"].name);
            if (findItem.count > 0) {
                findItem.count--;
                state.totalPrice = state.cartItems.reduce((sum, obj) => {
                    return (obj.base.price * obj.count) + sum;
                }, 0)
            }
        },
        removeItem: (state, action) => {
            state.cartItems = state.cartItems.filter((obj) => obj.main["ru"].name !== action.payload.main["ru"].name);
        },
        clearItems: (state) => {
            state.items = [];
            state.totalPrice = 0;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCartItems, minusItem, removeItem, clearItems } = cartSlice.actions

export default cartSlice.reducer