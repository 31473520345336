import React from "react";
import Footer from "../../components/Footer";
/* import { Link } from "react-router-dom"; */
import { useDispatch, useSelector } from "react-redux";
import { setCartItems, minusItem, removeItem } from '../../redux/slices/cartSlice'
import { Link } from "react-router-dom";



const Cart = () => {
    const dispatch = useDispatch();
    const { cartItems, totalPrice } = useSelector(state => state.cartSlice)
    const { lang } = useSelector(state => state.menuSlice)

    /*    const cartItem = cartItems.find(item => item.base.code === food.base.code && item.main[lang].name === food.main[lang].name); */

    return (

        <div className="App">
            <div className="cart-top">
                <h2>Ваш заказ</h2>
                <p>Покажите официанту</p>
                <Link to="/menu">
                    <svg className="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" /></svg></Link>

                {/* <svg>Закрыть</svg> */}
            </div>
            <div>
                {cartItems.map((obj, i) => {

                    const onClickMinusCart = (obj) => {
                        if (obj.count < 1) {

                            dispatch(removeItem(obj))
                        } else {
                            dispatch(minusItem(obj))
                        }
                    }

                    return (
                        <div className="cart-container" key={i}>
                            <div className="cart-itemBlock">
                                <div className="cart-itemAbout">
                                    <div className="img">
                                        <img src={obj.base.image} alt="dd" />
                                    </div>
                                    <div className="cart-itemLeft">

                                        <h3>{obj.main[lang].name}</h3>
                                        <div className="count">
                                            <svg onClick={() => onClickMinusCart(obj)} xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#EFEFEF"><path d="M280-453h400v-60H280v60ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                                            </svg>
                                            <div className="current-count">{obj.count}</div>
                                            <svg onClick={() => dispatch(setCartItems(obj))} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                                                <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="cart-itemRight" >
                                        <p className="food-price">{new Intl.NumberFormat('ru-RU').format(obj.base.price * obj.count)} ₸</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>)
                })}
            </div>
            <div className="sum1"><h3>Итого</h3><p>{new Intl.NumberFormat('ru-RU').format(totalPrice)}  ₸</p></div>
            <div className="sum2"><h3>Обслуживание - 10%</h3><p>{new Intl.NumberFormat('ru-RU').format(totalPrice * 0.1)}  ₸</p></div>
            <div className="sum3"><h3>Полная стоимость</h3><p>{new Intl.NumberFormat('ru-RU').format(totalPrice * 0.1 + totalPrice)} ₸</p></div>
            <Footer />
        </div>

    )
}

export default Cart;