import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found-block">
            <h4>😥</h4>
            <p>К сожалению, по вашему запросу ничего не найдено. В случаи возникнования трудностей, просим связаться с нашими менеджерами по номерам телефонов указанных внизу сайта!</p>
            <button><Link to={"/"}>Верунться на главную</Link></button>
        </div>
    )
}

export default NotFound;