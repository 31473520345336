import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchValue } from "../redux/slices/menuSlice";

const SearchBlock = () => {
    const dispatch = useDispatch();
    const { searchValue } = useSelector(state => state.menuSlice);
    const onChangeSearchValue = (event) => {
        dispatch(setSearchValue(event.target.value));
        console.log(searchValue);
    };
    return (
        <input className="searh-input" type="search" value={searchValue} onChange={onChangeSearchValue} placeholder="  Поиск" />
    );
}

export default SearchBlock;