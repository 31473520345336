import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { setActiveSubCategory } from "../redux/slices/menuSlice";

const SubCategoryBlock = ({ subcategoryRefs }) => {
    const dispatch = useDispatch();
    const { lang, filteredCategory, activeSubCategory } = useSelector(state => state.menuSlice);

    const scrollFunc = React.useCallback((index) => {
        if (subcategoryRefs.current[index]) {
            const offset = 60; // Смещение на 60px
            const elementPosition = subcategoryRefs.current[index].getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;
            dispatch(setActiveSubCategory(index));

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [dispatch, subcategoryRefs]);

    return (
        <div className="subcategory-block">
            {filteredCategory && filteredCategory.subcategory && filteredCategory.subcategory.map((subcategory, j) => (
                <div
                    className={activeSubCategory === j ? "active" : ""}
                    key={j}
                    onClick={() => scrollFunc(j)}
                >
                    {subcategory.main[lang].name}
                </div>
            ))}
        </div>
    );
}

export default SubCategoryBlock;