import React from "react";
import { Link } from "react-router-dom";
// ----- Компоненты
import Language from "../../components/Language";
import CategoryBlock from "../../components/CategoryBlock";
import SearchBlock from "../../components/SearchBlock";
import SubCategoryBlock from "../../components/SubCategoryBlock";
// ----- redux
import { useSelector, useDispatch } from "react-redux";
import { setActiveSubCategory, setFilteredCategory } from "../../redux/slices/menuSlice";
import { setCartItems, minusItem } from "../../redux/slices/cartSlice";

const MenuPage = () => {
  const dispatch = useDispatch();
  const { categoryS, lang, filteredCategory, data, searchValue } = useSelector(state => state.menuSlice);
  const { cartItems, totalPrice } = useSelector(state => state.cartSlice);

  const subcategoryRefs = React.useRef([]);
  const observer = React.useRef(null);

  React.useEffect(() => {
    console.log("MenuPage rendered");
  }, []);

  React.useEffect(() => {
    const updatedCategory = data.category.find(obj => obj.main[lang].name === categoryS);
    dispatch(setFilteredCategory(updatedCategory));
  }, [categoryS, lang, data, dispatch]);

  React.useEffect(() => {
    console.log('Cart items updated:', cartItems);
  }, [cartItems]);

  React.useEffect(() => {
    const currentRefs = subcategoryRefs.current;
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = currentRefs.indexOf(entry.target);
            dispatch(setActiveSubCategory(index));
          }
        });
      },
      { rootMargin: '-30px 0px 0px 0px', threshold: 0.5 }
    );

    currentRefs.forEach((ref) => {
      if (ref) observer.current.observe(ref);
    });

    return () => {
      if (observer.current) {
        currentRefs.forEach((ref) => {
          if (ref) observer.current.unobserve(ref);
        });
      }
    };
  }, [dispatch]);


  // Функция для извлечения всех блюд
  const extractFoods = () => {
    const allFoods = [];
    data.category.forEach(category => {
      if (category.subcategory && Array.isArray(category.subcategory)) {
        category.subcategory.forEach(subcategory => {
          if (subcategory.foods && Array.isArray(subcategory.foods)) {
            subcategory.foods.forEach(food => {
              allFoods.push(food);
            });
          }
        });
      }
    });
    return allFoods;
  };

  // Получение всех блюд из данных
  const allFoods = extractFoods();
  console.log(allFoods);

  return (

    <div className="App"
    >{cartItems.length > 0 && <Link className="buttun-cart" to='/cart'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#EFEFEF"><path d="M286.79-81Q257-81 236-102.21t-21-51Q215-183 236.21-204t51-21Q317-225 338-203.79t21 51Q359-123 337.79-102t-51 21Zm400 0Q657-81 636-102.21t-21-51Q615-183 636.21-204t51-21Q717-225 738-203.79t21 51Q759-123 737.79-102t-51 21ZM235-741l110 228h288l125-228H235Zm-30-60h589.07q22.97 0 34.95 21 11.98 21-.02 42L694-495q-11 19-28.56 30.5T627-453H324l-56 104h491v60H277q-42 0-60.5-28t.5-63l64-118-152-322H51v-60h117l37 79Zm140 288h288-288Z" /></svg>
      <p>{new Intl.NumberFormat('ru-RU').format(totalPrice)} ₸</p>
    </Link>}
      <div className="top-container">
        <Link className="logo" to={"/"}>The Art</Link>
        <Language />
        <SearchBlock />
      </div>
      {searchValue ? allFoods.filter(obj => obj.main[lang].name.toLowerCase().includes(searchValue.toLowerCase())).map((food, i) => {
        const cartItem = cartItems.find(item => item.base.code === food.base.code && item.main[lang].name === food.main[lang].name);
        const addedCount = cartItem ? cartItem.count : 0;
        return (<div className="food-block" ><div className="food-subblock" key={i}>
          <div className="food-siblock">
            <div className="food-image">
              <img src={food.base.image} alt={food.main[lang].name} />
            </div>
            <div className="food-item">
              <div className="food-item2">
                <h4 className="food-name">{food.main[lang].name}</h4>
                <p className="food-desc">{food.main[lang].description}</p>
              </div>
              <p className="food-price">{new Intl.NumberFormat('ru-RU').format(food.base.price)} ₸</p>
              <div className="count-block">
                {addedCount > 0 ? (
                  <div className="count">
                    <svg onClick={() => dispatch(minusItem(food))} xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#EFEFEF"><path d="M280-453h400v-60H280v60ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                    </svg>
                    <div className="current-count">{addedCount}</div>
                    <svg onClick={() => dispatch(setCartItems(food))} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                      <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
                    </svg>
                  </div>
                ) : (
                  <svg onClick={() => dispatch(setCartItems(food))} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                    <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <hr />
        </div></div>
        )
      }) : <> <CategoryBlock />
        <SubCategoryBlock subcategoryRefs={subcategoryRefs} />

        {filteredCategory && filteredCategory.subcategory && filteredCategory.subcategory.map((subcategory, j) => (
          <div className="food-block" key={j} ref={(el) => subcategoryRefs.current[j] = el}>
            <h3 className="food-g-name">{subcategory.main[lang].name}</h3>
            {subcategory.foods && subcategory.foods.map((food, u) => {
              const cartItem = cartItems.find(item => item.base.code === food.base.code && item.main[lang].name === food.main[lang].name);
              const addedCount = cartItem ? cartItem.count : 0;

              return (
                <div className="food-subblock" key={u}>
                  <div className="food-siblock">
                    <div className="food-image">
                      <img src={food.base.image} alt={food.main[lang].name} />
                    </div>
                    <div className="food-item">
                      <div className="food-item2">
                        <h4 className="food-name">{food.main[lang].name}</h4>
                        <p className="food-desc">{food.main[lang].description}</p>
                      </div>
                      <p className="food-price">{new Intl.NumberFormat('ru-RU').format(food.base.price)} ₸</p>
                      <div className="count-block">
                        {addedCount > 0 ? (
                          <div className="count">
                            <svg onClick={() => dispatch(minusItem(food))} xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#EFEFEF"><path d="M280-453h400v-60H280v60ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                            </svg>
                            <div className="current-count">{addedCount}</div>
                            <svg onClick={() => dispatch(setCartItems(food))} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                              <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
                            </svg>
                          </div>
                        ) : (
                          <svg onClick={() => dispatch(setCartItems(food))} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                            <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        ))}</>}


    </div>
  );
};

export default MenuPage;
