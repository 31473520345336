import React from "react";
import Language from "../../components/Language";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";



const Main = () => {

  return (
    <>
      <div className="App-spotContent">
        <div className="topImage">
          <img src="../../images/mainPageImg/gallery.jpg" alt="mainImg" />
        </div>
        <Language />
        <div className="titleblock">
          <h3>The Art</h3>
          <p>Изысконная кухня в галерее скульптур 🤤</p>
          <p>Обслуживание - 10%</p>
        </div>
        <li className="list">
          <Link to="/"> <div><img src="../../../images/icons/store.png" alt="dd" /><p>Смотреть интерьер</p></div> <img src="../../../images/icons/double_arrow.png" alt="dd" /></Link>
          <Link to="/menu"> <div><img src="../../../images/icons/menu_book.png" alt="dd" /><p>Смотреть меню</p> </div> <img src="../../../images/icons/double_arrow.png" alt="dd" /></Link>
          <Link to="https://api.whatsapp.com/send/?phone=77713968344&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%B2%D0%B0%D1%88%D0%B5%D0%B3%D0%BE+%D1%81%D0%B0%D0%B9%D1%82%D0%B0.+%D0%A5%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%B1%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C+%D1%83+%D0%B2%D0%B0%D1%81+%D1%81%D1%82%D0%BE%D0%BB%D0%B8%D0%BA!%0A%0A&type=phone_number&app_absent=0"> <div><img src="../../../images/icons/order.png" alt="dd" /><p>Забронировать столик</p> </div><img src="../../../images/icons/double_arrow.png" alt="dd" /></Link>
        </li>
        <div className="about">
          <div>
            <img src="../../../images/icons/map.png" alt="655" /> <div><h4>Местоположение</h4><p>г. Астана, проспект Абая, 27</p><a target="blank" href="https://2gis.kz/astana/firm/70000001088873740?m=71.416934%2C51.167668%2F16">Открыть в 2ГИС</a></div>
          </div>
          <div>
            <img src="../../../images/icons/schedule.png" alt="655" /> <div><h4>Режим работы</h4><p>пн-пт 12:00-02:00</p><p>сб-вс 12:00-03:00</p></div>
          </div>
          <div>
            <img src="../../../images/icons/call.png" alt="655" /> <div><h4>Контакты</h4><a target="blank" href="tel:+77713968344">+7‒771‒396‒83‒44</a></div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Main;