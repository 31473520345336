import './scss/App.scss'; // ----- Стили
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// ----- Страницы -----
import NotFound from './pages/mainPages/NotFound';
import MenuPage from './pages/mainPages/MenuPage';
import Main from './pages/mainPages/Main';
import Cart from './pages/mainPages/Cart';

function App() {
  // ----- для проверки просто пусть будет
  React.useEffect(() => {
    console.log('App rendered');
  });

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Main />}></Route>
        <Route path='/menu' element={<MenuPage />}></Route>
        <Route path='/cart' element={< Cart />}></Route>
        <Route path='*' element={<NotFound />} ></Route>
      </Routes>
    </div>
  );
}

export default App;
