import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { setActiveCategory, setCategoryS } from "../redux/slices/menuSlice";

const CategoryBlock = () => {
    const dispatch = useDispatch();
    const { activeCategory, lang, data } = useSelector(state => state.menuSlice);

    const activeCategoryFunc = React.useCallback((obj, i) => {
        dispatch(setCategoryS(obj.main[lang].name));
        dispatch(setActiveCategory(i));
        window.scrollTo(0, 0);
    }, [dispatch, lang]);

    return (
        <div className="category-block">
            {data.category.map((obj, i) => (
                <div className={activeCategory === i ? "active" : "single-block"} key={i}>
                    <div onClick={() => activeCategoryFunc(obj, i)}>
                        <img src={obj.base.image} alt="gg" />
                    </div>
                    <h4>{obj.main[lang].name}</h4>
                </div>
            ))}
        </div>
    );
}

export default CategoryBlock;