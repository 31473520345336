import React from "react";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <>
            <hr></hr>
            <li className="socialN">
                <a target="blank" href="https://api.whatsapp.com/send/?phone=77713968344&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%B2%D0%B0%D1%88%D0%B5%D0%B3%D0%BE+%D1%81%D0%B0%D0%B9%D1%82%D0%B0.%0A%0A&type=phone_number&app_absent=0"><img src="../../../images/icons/whatsapp.png" alt="whatsapp" /></a>
                <a target="blank" href="https://www.instagram.com/theart.astana/?igsh=cWE3M2Z0ZWZnYWNi"><img src="../../../images/icons/instagram.png" alt="instagram" /></a>
                <a target="blank" href="/"><img src="../../../images/icons/telegram.png" alt="telegram" /></a>
               
            </li>
            <hr></hr>
            <div className='footer-bottom'>
                <div>Copyright © {year} The Art.kz. Все права защищены.</div>
                <div>Разработано - <a target="blank" href='https://www.instagram.com/niyazbektembayev/?igsh=MXI3Yzd1em13eDV4Yw%3D%3D&utm_source=qr'>NB DESIGN</a></div>
            </div>
        </>

    );
}

export default Footer;